import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './shared/components/error/error.component';

const routes: Routes = [
  { path: '', redirectTo: 'templates', pathMatch: 'full' },

  {
    path: 'secure',
    loadChildren: () => import('./secure/secure.module').then((m) => m.SecureModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: 't2c',
    loadChildren: () => import('./t2c/t2c.module').then((m) => m.T2cModule),
  },
  {
    path: 'industrial',
    loadChildren: () => import('./industrial/industrial.module').then((m) => m.IndustrialModule),
  },
  {
    path: 'predesign',
    loadChildren: () => import('./predesign/predesign.module').then((m) => m.PredesignModule),
    data: { predesign: 'diy' },
  },
  {
    path: 'about-us/legal',
    loadChildren: () =>
      import('./consumer-privacy/consumer-privacy.module').then((m) => m.ConsumerPrivacyModule),
  },
  {
    path: 'custom-printing',
    loadChildren: () => import('./weprint/weprint.module').then((m) => m.WeprintModule),
  },
  {
    path: 'srch',
    loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'myaccount',
    loadChildren: () => import('./my-account/my-account.module').then((m) => m.MyAccountModule),
  },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
